/*
 * @Author: chenxing
 * @Date: 2021-04-16 20:31:51
 * @LastEditors: chenxing
 * @LastEditTime: 2021-04-26 20:27:01
 */
import axios from '@/utils/axios';

const apiUrl = {
    getActorTimePage: '/material/actorTime/getPage',
    saveOneActor: '/material/actorTime/save',
    updateActor: '/material/actorTime/update',
    getActorList: '/material/materialActor/getList',
    getActorDeptList: '/material/actorTime/getDeptList',
    createActor: '/material/materialActor/saveOnlyNameAndPhone'
};

export function getActorTimePageData(params) {
    return axios.post(apiUrl.getActorTimePage, params);
}
export function saveOneActorData(params) {
    return axios.post(apiUrl.saveOneActor, params);
}
export function updateActorData(params) {
    return axios.post(apiUrl.updateActor, params);
}
export function getActorListData(params) {
    return axios.post(apiUrl.getActorList, params);
}
export function getActorDeptListData() {
    return axios.get(apiUrl.getActorDeptList);
}

/** 新增演员
 * @param {*}
 * @return {*}
 */
export function saveCreateActorData(params) {
    return axios.post(apiUrl.createActor, params);
}