<!--
 * @Author: zhangruifeng
 * @Date: 2021-06-04 17:09:37
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-22 14:47:27
-->
<template>
  <a-select v-model:value="selectedValue" placeholder="请选择统计口径" :dropdown-match-select-width="false" dropdown-class-name="tjkj-select" :get-popup-container="getPopupContainer">
    <template #suffixIcon><SyncOutlined /></template>

    <template #dropdownRender>
      <div v-for="kjItem in tjkjList" :key="kjItem" class="tjkj-item flexJustifyBetween" :class="{ 'tjkj-item-selected': selectedKey == kjItem.key }" @mousedown="e => e.preventDefault()" @click="selectItem(kjItem)">
        <div style="width: 20px; line-height: 22px"><CheckOutlined v-if="selectedKey == kjItem.key" class="icon-check" /></div>
        <div style="flex: 1">
          <div class="item-title">
            <p>{{ kjItem.title }}</p>
          </div>
          <span class="item-content">
            <p v-for="content in kjItem.details" :key="content">{{ content }}</p>
          </span>
        </div>
      </div>
      <a-checkbox class="padding10" v-if="tjkjTips && selectedKey == 'REPORTING_TIME'" v-model:checked="checked" @change="tjkTipsChange">{{ tjkjTips }}</a-checkbox>
    </template>
  </a-select>
</template>

<script>
import { CheckOutlined, SyncOutlined } from '@ant-design/icons-vue';
export default {
  emits: ['selectItem'],
  components: { CheckOutlined, SyncOutlined },
  props: {
    selected: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tjkjList: {
      type: Array,
      default: () => {
        return [
          { key: 'REQUEST_TIME', title: '广告播放', details: ['转换数据统计至广告播放时间点', '适合场景:广告赔付考察、广告整体生命周期表现'] },
          { key: 'REPORTING_TIME', title: '转化回传', details: ['转换数据统计至行为上报时间点', '适合场景:实时查看当日上报数据情况'] },
          { key: 'ACTIVE_TIME', title: '激活口径', details: ['转化数据统计至行为上报时间点', '其次次留、APP付费及激活等行为统计至激活时间点', '适合场景：查看激活后长期留存和付费情况'] },
        ];
      },
    },
    tjkjTips: {
      type: String,
      default: null,
    },
    setPopupContainer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: '转化回传',
      selectedKey: 'REPORTING_TIME',
      checked: false,
    };
  },
  watch: {
    selected: {
      handler(newVal) {
        if (newVal.selectedKey && newVal.selectedKey == 'ACTIVE_TIME') {
          this.checked = true;
          this.selectedKey = 'REPORTING_TIME';
          this.selectedValue = '转化回传（激活）';
        } else {
          this.checked = false;
          newVal && (this.selectedKey = newVal.selectedKey || 'REPORTING_TIME');
          newVal && (this.selectedValue = newVal.selectedValue || '转化回传');
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.selected.selectedKey && this.selected.selectedKey == 'ACTIVE_TIME') {
      this.checked = true;
      this.selectedKey = 'REPORTING_TIME';
      this.selectedValue = '转化回传（激活）';
    } else {
      this.checked = false;
      this.selected && (this.selectedKey = this.selected.selectedKey || 'REPORTING_TIME');
      this.selected && (this.selectedValue = this.selected.selectedValue || '转化回传');
    }
  },
  methods: {
    getPopupContainer(triggerNode) {
      return this.setPopupContainer ? triggerNode.parentNode : document.body;
    },
    selectItem(item) {
      this.selectedValue = item.title;
      this.selectedKey = item.key;
      this.selectedKey == 'REQUEST_TIME' && (this.checked = false);
      this.$emit('selectItem', item.key, this.checked);
    },
    tjkTipsChange(e) {
      this.selectedKey == 'REQUEST_TIME' && (this.checked = false);
      this.$emit('selectItem', this.selectedKey, this.checked);
      this.selectedValue = e.target.checked ? '转化回传（激活）' : '转化回传';
    },
  },
};
</script>

<style lang="less" scoped>
.tjkj-select {
  .tjkj-item {
    cursor: pointer;
    padding: 0.5em 1em;
    .item-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      p {
        font-weight: 600;
        font-size: 0.44rem;
      }
    }

    .item-content {
      p {
        color: #bfbfbf;
      }
    }
  }
  .tjkj-item-selected {
    .item-title {
      p {
        color: #49a3ff;
      }
    }
    .item-content {
      p {
        font-weight: 600;
        color: #929292;
      }
    }
  }
  .tjkj-item:hover {
    background-color: #f5f5f5;
  }
  .icon-check {
    color: #49a3ff;
  }
}
</style>