<template>
  <div class='material_info_tab'>
    <div class='asset_info'>
      <a-row class='img-style'>
        <img v-if="type === 'IMAGE'" :src='materialDetailsMessage.ossurl' :alt='materialDetailsMessage.fileName' />
        <video-player @playStatusChange='status => (videoPlayerPlay = status)' v-else id='material_detail_player'
                      :play='videoPlayerPlay' :src='materialDetailsMessage.ossurl' :video-style='videoStyle' />
        <div class='show-tag-wrapper'>
          <a-row class='show-tags' v-if='tagList.length > 0'>
            <a-col :span='2'> 标签：</a-col>
            <div>
              <a-tag v-for='(tag, index) in tagList' :key='index' :color='tagColors[index % 7]'>标签{{ tag }}</a-tag>
            </div>
          </a-row>
          <a-row class='show-interests' v-if='labelList.length > 0'>
            <a-col :span='2'> 利益点：</a-col>
            <div>
              <a-tag v-for='(label, index) in labelList' :key='index' :color='tagColors[index % 7]'>利益点{{ label }}
              </a-tag>
            </div>
          </a-row>
        </div>
      </a-row>
      <!-- 视频下方按钮-->
      <div class='table-toolbar flexJustifyBetween'>
        <div class='alink-color'>
          <a-space class='toolbar-left' :size='40'>
            <a v-hasPermission='`MATERIAL_BATCHPUSH`'
               @click='singlePush(materialDetailsMessage)'>
              <SendOutlined />
              推送
            </a>
            <a v-hasPermission='`MATERIAL_BATCHDOWNLOAD`' @click='getDownloadUrl(materialDetailsMessage)'
               :href='materialDetailsMessage.downloadurl'>
              <DownloadOutlined />
              下载
            </a>
            <a @click='copyUrl(materialDetailsMessage)'>
              <copyOutlined />
              复制链接
            </a>
            <a v-hasPermission='`MATERIAL_REPLACE`' class='blue-btn replace pointer'
               :style="{ cursor: materialDetailsMessage.costStatus == '未使用' ? 'pointer' : 'no-drop' }"
               @click="uploadMaterial({ type: 'update' })">
              <ApiOutlined />
              素材替换
            </a>
          </a-space>
        </div>
      </div>
      <div class='form_wrapper'>
        <!-- 基本信息 -->
        <div class='module_title'>
          <span>基本信息</span>
          <div class='blue-btn edit' @click="uploadMaterial({ type: 'edit' })" style='padding: 0 18px'>
            <EditOutlined />
            编辑
          </div>
        </div>
        <a-form>
          <a-row>
            <a-col :span='12'>
              <a-form-item  :label-col='{ span: 6}' :wrapper-col='{ span: 18}' label='素材保护'>{{ }}</a-form-item>
            </a-col>
            <a-col :span='12'>
              <a-form-item label='保护端口'>{{  }}</a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span='8'>
              <a-form-item label='素材ID'>{{ materialDetailsMessage.id }}</a-form-item>
            </a-col>
            <a-col :span='8'>
              <a-form-item label='分辨率'>
                {{ `${materialDetailsMessage.resolutionLong} * ${materialDetailsMessage.resolutionHeight}` || '-' }}
              </a-form-item>
            </a-col>
            <a-col :span='8'>
              <a-form-item label='素材类型'>{{ materialType[materialDetailsMessage.materialChannel] || '-' }}</a-form-item>
            </a-col>
          </a-row>
          <a-row >
            <a-col :span='8'>
              <a-form-item label='平均成本'>{{ materialDetailsMessage.id }}</a-form-item>
            </a-col>
            <a-col :span='8'>
              <a-form-item label='上传部门'>
                {{ `${materialDetailsMessage.resolutionLong} * ${materialDetailsMessage.resolutionHeight}` || '-' }}
              </a-form-item>
            </a-col>
            <a-col :span='8'>
              <a-form-item label='使用部门'>
                {{ materialDetailsMessage.useDeptId ? columnMap(filterDepIdList, materialDetailsMessage.useDeptId, 'deptId', 'name') : '-'
                }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span='12'>
              <a-form-item label='MD5'> {{ materialDetailsMessage.md5 }}</a-form-item>
            </a-col>
            <a-col :span='12'>
              <a-form-item label='使用媒体'>{{ getChannelStr(materialDetailsMessage.mediaChannel) }}</a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span='24'>
              <a-form-item label='备注信息'> {{ materialDetailsMessage.remarkInfo ? materialDetailsMessage.remarkInfo : '-'
                }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item label='上传时间'> {{ materialDetailsMessage.createTime || '-' }}</a-form-item>
        </a-form>
        <!-- 关联信息 -->
        <div>
          <div class='module_title' style='margin-top: 8px'>
            <span> 关联信息 </span>
          </div>
          <a-form :label-col='{ span: 6 }' :wrapper-col='{ span: 20 }'>
            <a-row  class='denied-trial-wrapper'>
              <a-col :span='12'>
                <a-form-item label='关联产品'> {{ materialDetailsMessage.ofProjectName }}</a-form-item>
              </a-col>
              <a-col :span='12'>
                <a-form-item label='所属行业'>{{ }}</a-form-item>
              </a-col>
            </a-row>
            <a-row  class='denied-trial-wrapper'>
              <a-col :span='12'>
                <a-form-item label='关联脚本'>{{  }}</a-form-item>
              </a-col>
              <a-col :span='12'>
                <a-form-item label='脚本类型'>{{ }}</a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 人员信息 -->
        <div>
          <div class='module_title' style='margin-top: 8px'>人员信息</div>
          <a-form :label-col='{ span: 6 }' :wrapper-col='{ span: 20 }'>
            <a-row v-if="materialDetailsMessage.materialChannel == 'QJJ'">
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='制片人'>{{ detailPersonInfo?.producerName || '-' }}</a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='编导人员'>{{ detailPersonInfo?.screenwriterName || '-' }}
                </a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='摄影人员'>{{ detailPersonInfo?.shootingName || '-' }}
                </a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='后期人员'> {{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='上传人员'>{{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='上传信息'>{{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='演 员'> {{ detailPersonInfo?.actorNameList?.join(',') || '-'
                  }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row
              v-else-if="materialDetailsMessage.materialChannel == 'YSJJ' || materialDetailsMessage.materialChannel == 'EC'">
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='后期人员'>{{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
            </a-row>
            <a-row v-else-if="materialDetailsMessage.materialChannel == 'HJ'">
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='摄影人员'>{{ detailPersonInfo?.shootingName || '-' }}
                </a-form-item>
              </a-col>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='后期人员'> {{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
            </a-row>
            <a-row v-else>
              <a-col :span='assetInfoSpan'>
                <a-form-item style='width: 100%' label='上传人员'>{{ detailPersonInfo?.postName || '-' }}</a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <!-- 相似素材 -->
        <!-- 7.30相似素材需求未上线！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！ -->
        <div class='similar_material w100' v-if='false'>
          <div class='module_title'>
            <span>相似素材</span>
            <a-tag color='#108ee9' @click="uploadMaterial('analyze')">素材分析</a-tag>
          </div>
          <div class='material_list w100'>
            <div class='material_item' v-for='(item, index) in similarMaterialList' :key='index'>
              <div class='img_wrapper'>
                <!-- <img class="w100 h100" :src="require(item.url)" alt="" /> -->
                <img class='w100 h100' src='' alt='' />
              </div>
              <p class='material_name'>添加相似素材{{ index }}</p>
              <CloseCircleFilled @click='deleteMaterial(item, index)' />
            </div>
            <div class='material_item'>
              <div class='upload_box pointer'>
                <PlusOutlined />
                <p>添加相似素材</p>
                <div class='upload_select'>
                  <p @click="uploadMaterial({ type: 'local' })">本地添加</p>
                  <p @click="uploadMaterial({ type: 'assets' })">素材库添加</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 批量推送 -->
  <batch-push v-model:visible='batchPushShow' :material-list='batchPushMaterial' material-type='VIDEO' />

  <add-similar-material v-model:visible='modalVisible'></add-similar-material>
  <denied-trial-modal v-model:visible='deniedTrialModalVisible'
                      :material-id='materialDetailsMessage.id'></denied-trial-modal>
</template>

<script>
import moment from 'moment';
import getMediaTableColumns, { echartsOptions } from './mediaTableColumns.js';
import {
  PlusOutlined,
  CloseCircleFilled,
  CalendarTwoTone,
  SendOutlined,
  DownloadOutlined,
  ApiOutlined,
  CopyOutlined,
} from '@ant-design/icons-vue';
import { addChineseUnit, isNumber, unique } from '@/utils/utils.js';
import {
  getMaterialPersonInfoData,
  getCostByMd5,
  getAssociateDetailedData,
  getAssociateDetailedDataCancel,
  getTrendData,
  getLabelInfo,
} from '@/api/material/material.js';
import videoPlayer from '@/components/Video/videoPlayer';
import AddSimilarMaterial from './AddSimilarMaterial.vue';
import DeniedTrialModal from './DeniedTrialModal.vue';
import BaseChart from './BaseChart.vue';
import { Modal } from 'ant-design-vue';
import { channelDict } from '@/config/globalData.js';
import caliber from '@/components/Customize/caliber.vue';
import BatchPush from '@/views/material/components/batchPushModal.vue';
import { getOpUrl, getOpDownloadUrl } from '@/utils/op_oss.js';
import { getUrl, getDownloadUrl } from '@/utils/oss.js';

export default {
  name: '',
  components: {
    // caliber,
    PlusOutlined,
    CloseCircleFilled,
    videoPlayer,
    AddSimilarMaterial,
    // BaseChart,
    DeniedTrialModal,
    SendOutlined,
    DownloadOutlined,
    ApiOutlined,
    CopyOutlined,
    BatchPush,
  },
  emits: ['uploadMaterial'],
  inject: ['getMenuListSource', 'getFilterDepIdList'],
  props: {
    // 素材类型(IMAGE、VIDEO)
    type: { type: String, default: 'IMAGE' },
    // 素材详情数据
    materialDetailsMessage: {
      type: Object, default: () => {
      },
    },
    // 筛选条件
    filterCondition: {
      type: Object, default: () => {
      },
    },
    mediaChannel: { type: String, default: null },
  },
  data() {
    return {
      batchPushMaterial: [], //推送数据
      batchPushShow: false, // 推送弹窗显示
      tagList: [], // 标签列表
      labelList: [], // 利益点列表
      tagColors: ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple'],
      // 口径选择项
      tjkjList: [
        { key: 'REQUEST_TIME', title: '广告播放', details: ['转换数据统计至广告播放时间点', '适合场景:广告赔付考察、广告整体生命周期表现'] },
        { key: 'REPORTING_TIME', title: '转化回传', details: ['转换数据统计至行为上报时间点', '适合场景:实时查看当日上报数据情况'] },
      ],
      tjkjSelected: {
        selectedKey: null,
        selectedValue: null,
      },
      timeLine: 'REPORTING_TIME', //口径
      options: {},
      indexOneKey: { value: 'cost', label: '消耗', key: 'cost' },
      indexTwoKey: { value: 'ctr', label: '点击率', key: 'ctr' },
      allIndicator: [], //所有指标
      channelDict,
      modalVisible: false, //上传相似素材弹框
      deniedTrialModalVisible: false, //素材拒审弹框
      materialDetailsKey: '1',
      assetInfoSpan: 12,
      userInfoGrades: this.$store.state.oauth.userInfo.grades,
      userInfoJobs: this.$store.state.oauth.userInfo.jobs,
      // materialType: { QJJ: '情景剧', GJSC:'共建素材',GJ_TP_10:'共建-套皮10分钟',YSJJ: '剪辑', CG: '采购', KHTG: '客户提供', NBZZ: '内部制作', WBCG: '外部采购', HJ: '混剪', EC: '二创剪辑',AIJJ:'AI剪辑',AIJJ_KG:'AI剪辑(客供)',TP_QJJ: '套皮-情景剧',  TP_HQ_PY: '套皮-后期-配音', TP_HQ_JZ: '套皮-后期-节奏', TP_HQ_QT: '套皮-后期-前贴',TP_HQ_5: '套皮-后期-5分钟', TP_HQ_15: '套皮-后期-15分钟', TP_HQ_30: '套皮-后期-30分钟', TP_EC: '套皮-二次剪辑'},
      materialType: {
        QJJ: '情景',
        GJSC: '共建素材',
        GJ_TP_10: '共建-套皮10分钟',
        YSJJ: '剪辑',
        YSJJ_2X: '剪辑*2',
        YSJJ_3X: '剪辑*3',
        YSJJ_4X: '剪辑*4',
        YSJJ_7X: '剪辑*7',
        CG: '采购',
        KHTG: '客户提供',
        NBZZ: '图片',
        WBCG: '外部采购',
        HJ: '剪辑-混剪',
        EC: '二创剪辑',
        AIJJ: '剪辑-Ai',
        AIJJ_KG: 'AI剪辑(客供)',
        TP_QJJ: '情景-1/2',
        TP_HQ_PY: '套皮-后期-配音',
        TP_HQ_JZ: '情景-混剪',
        TP_HQ_QT: '套皮-后期-前贴',
        TP_HQ_15: '剪辑-1/5',
        TP_HQ_30: '剪辑-1/2',
        TP_EC: '情景-二改',
        TP_EC_WC: '情景-二改-外采',
        TP_HJ_WC: '情景-混剪-外采',
        TP_EC_WB: '情景-二改-外部',
        TP_HJ_WB: '情景-混剪-外部',
      },
      collapseKey: null, // 关联详细数据折叠
      detailPersonInfo: {},
      // 投放时间
      postTime: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
      collapseList: [
        {
          name: '巨量引擎2.0',
          time: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
          key: 'TT_2',
          countCost: 0,
        },
        {
          name: '腾讯广告',
          time: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
          key: 'GDT',
          countCost: 0,
        },
        {
          name: '巨量引擎',
          time: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
          key: 'TT',
          countCost: 0,
        },
        {
          name: '磁力引擎',
          time: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
          key: 'KS',
          countCost: 0,
        },
        {
          name: '巨量千川',
          time: [this.filterCondition.startMaterialLaunchDate, this.filterCondition.endMaterialLaunchDate],
          key: 'QC',
          countCost: 0,
        },

      ], // 关联详细数据
      mediaTableKey: 'MTZH-关联媒体账号', // 关联详细数据折叠中tab页切换
      mediaTableData: [],
      tableLoading: false,
      isUserClick: false,
      tableModalSorter: { order: 'desc', orderField: ['cost'] },
      tableModalPagination: {
        limit: '10',
        page: '1',
        pageSize: 10,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      videoStyle: {},
      videoPlayerPlay: true,
      similarMaterialList: [],
      siteSetOptions: [
        { value: 'SITE_SET_MOBILE_UNION', label: '优量汇' },
        { value: 'SITE_SET_WECHAT', label: '微信公众号与小程序' },
        { value: 'SITE_SET_MOBILE_MYAPP', label: '应用宝' },
        { value: 'SITE_SET_MOBILE_INNER', label: 'QQ、腾讯看点、腾讯音乐' },
        { value: 'SITE_SET_TENCENT_NEWS', label: '腾讯新闻' },
        { value: 'SITE_SET_TENCENT_VIDEO', label: '腾讯视频' },
        { value: 'SITE_SET_MOBILE_YYB', label: '应用宝' },
        { value: 'SITE_SET_PCQQ', label: 'PC QQ、QQ 空间、腾讯音乐' },
        { value: 'SITE_SET_KANDIAN', label: '腾讯看点' },
        { value: 'SITE_SET_QQ_MUSIC_GAME', label: 'QQ、腾讯音乐及游戏' },
        { value: 'SITE_SET_MOMENTS', label: '微信朋友圈' },
        { value: 'SITE_SET_MINI_GAME_WECHAT', label: '微信小游戏' },
        { value: 'SITE_SET_MINI_GAME_QQ', label: 'QQ 小游戏' },
        { value: 'SITE_SET_MOBILE_GAME', label: 'App 游戏' },
        { value: 'SITE_SET_QQSHOPPING', label: 'QQ 购物' },
        { value: 'INVENTORY_FEED', label: '头条信息流' },
        { value: 'INVENTORY_VIDEO_FEED', label: '西瓜信息流' },
        { value: 'INVENTORY_HOTSOON_FEED', label: '火山信息流' },
        { value: 'INVENTORY_AWEME_FEED', label: '抖音信息流' },
        { value: 'INVENTORY_UNION_SLOT', label: '穿山甲' },
        { value: 'UNION_BOUTIQUE_GAME', label: 'ohayoo精品游戏' },
        { value: 'INVENTORY_UNION_SPLASH_SLOT', label: '穿山甲开屏广告' },
        { value: 'INVENTORY_AWEME_SEARCH', label: '搜索广告——抖音位' },
        { value: 'INVENTORY_SEARCH', label: '搜索广告——头条位' },
        { value: 'INVENTORY_UNIVERSAL', label: '通投智选' },
        { value: 'INVENTORY_BEAUTY', label: '轻颜相机' },
        { value: 'INVENTORY_PIPIXIA', label: '皮皮虾' },
        { value: 'INVENTORY_AUTOMOBILE', label: '懂车帝' },
        { value: 'INVENTORY_STUDY', label: '好好学习' },
        { value: 'INVENTORY_FACE_U', label: 'faceu' },
        { value: 'INVENTORY_TOMATO_NOVEL', label: '番茄小说' },
        { value: 'INVENTORY_FEED', label: '头条系' },
      ],
      tabList: {
        GDT: [
          { tab: '关联媒体账号', key: 'MTZH-关联媒体账号' },
          { tab: '关联推广计划', key: 'TGJH-关联推广计划' },
          { tab: '关联广告', key: 'GG-关联广告' },
          { tab: '关联广告创意', key: 'GGCY-关联广告创意' },
        ],
        TT: [
          { tab: '关联媒体账号', key: 'MTZH-关联媒体账号' },
          { tab: '关联广告组', key: 'TGJH-关联广告组' },
          { tab: '关联广告计划', key: 'GG-关联广告计划' },
          { tab: '关联广告创意', key: 'GGCY-关联广告创意' },
        ],
        KS: [
          { tab: '关联媒体账号', key: 'MTZH-关联媒体账号' },
          { tab: '关联推广计划', key: 'TGJH-关联推广计划' },
          { tab: '关联广告组', key: 'GG-关联广告组' },
          { tab: '关联广告创意', key: 'GGCY-关联广告创意' },
        ],
        QC: [
          { tab: '关联账号', key: 'MTZH-关联媒体账号' },
          { tab: '关联计划', key: 'GG-关联计划' },
        ],
        TT_2: [
          { tab: '关联媒体账号', key: 'MTZH-关联媒体账号' },
          { tab: '关联项目', key: 'XM-关联项目' },
          { tab: '广告', key: 'GG-广告' },
        ],
      },
    };
  },
  watch: {
    'materialDetailsMessage.id'() {
      this.collapseKey = this.materialDetailsMessage.mediaChannel?.split(',')?.[0] !== '-' ? this.materialDetailsMessage.mediaChannel?.split(',')?.[0] : null;
      this.getPersonInfo();
      this.getCostByMd5(this.materialDetailsMessage);
      this.getLabelInfo();
    },
    'materialDetailsMessage.label'() {
      this.setLabelList(this.materialDetailsMessage);
    },
  },
  computed: {
    mediaTableColumns() {
      let columns = [];
      const label = this.mediaTableKey?.split('-')?.[1];
      const mediaTableColumns = getMediaTableColumns(this.type);
      switch (label) {
        case '关联媒体账号':
          columns = mediaTableColumns.glmtzh;
          break;
        case '关联推广计划':
          columns = mediaTableColumns.gltgjh;
          break;
        case '关联广告':
          columns = mediaTableColumns.glgg;
          break;
        case '关联广告创意':
          columns = mediaTableColumns.glggcy;
          break;
        case '关联广告组':
          if (this.collapseKey === 'KS') {
            columns = mediaTableColumns.glggzKS;
          } else {
            columns = mediaTableColumns.glggzOther;
          }
          break;
        case '关联广告计划':
          columns = mediaTableColumns.glggjh;
          break;
        case '关联计划':
          columns = mediaTableColumns.QCgljh;
          break;
        case '关联项目':
          columns = mediaTableColumns.xm;
          break;
        case '广告':
          columns = mediaTableColumns.Gg2;
          break;
      }
      return columns;
    },
    //趋势图筛选项（非属性指标）
    selectOptions() {
      const menuList = this.getMenuListSource();
      let channelData = menuList.find(item => item.key?.includes(this.collapseKey))?.info || []; //渠道指标
      let shareData = menuList.find(item => item.key?.includes('SHARE_DATA'))?.info || []; //共有指标

      shareData = shareData.filter((item, index) => {
        return !item?.notSupportChannel?.includes(this.collapseKey) && !item.columnValue?.includes('日期');
      });
      channelData = channelData.filter((item, index) => {
        return !item.columnValue?.includes('日期');
      });
      channelData = channelData.concat(shareData);
      channelData.forEach(item => {
        item.label = item.columnValue;
        item.value = item.columnKey;
      });
      return channelData;
    },
    filterDepIdList() {
      return this.getFilterDepIdList();
    },
  },

  async created() {
    this.collapseKey = this.materialDetailsMessage.mediaChannel?.split(',')?.[0] !== '-' ? this.materialDetailsMessage.mediaChannel?.split(',')?.[0] : null;
    if (this.mediaChannel) {
      this.collapseKey = this.mediaChannel;
    }
    await this.getPersonInfo();
    await this.getCostByMd5(this.materialDetailsMessage);
    this.getLabelInfo();
  },
  mounted() {
    this.getSimilarMaterialList();
  },
  methods: {
    // 单个推送
    singlePush(item) {
      this.batchPushShow = true;
      this.batchPushMaterial = [
        { materialId: item.id, url: item.url, md5: item.md5, fileName: item.fileName },
      ];
    },
    /** 获取文件下载链接
     * @param {*} record
     * @return {*}
     */
    async getDownloadUrl(record) {
      if (!record.downloadurl) {
        if (record.makeCode.startsWith('op')) {
          record.downloadurl = await getOpDownloadUrl(record.url, record.fileName);
        } else {
          record.downloadurl = await getDownloadUrl(record.url, record.fileName);
        }
      }
      return record.downloadurl;
    },
    /** 复制链接
     * @param {*} record
     * @return {*}
     */
    async copyUrl(record) {
      console.error(record.url);
      navigator.clipboard.writeText('https://oss-cn-hangzhou-internal.aliyuncs.com' + record.url);
      this.$message.success('已复制');
    },
    getChannelStr(keys) {
      if (!keys) return '-';
      let _this = this;
      let str = keys.split(',').reduce((pre, cur) => {
        if (!pre) return _this.channelDict[cur];
        else return pre + '，' + _this.channelDict[cur];
      }, '');
      if (str) return str;
      else return '-';
    },
    /**
     * @description: 获取利益点列表
     * @param {*} record
     * @return {*}
     */
    setLabelList(record) {
      let list = record.label?.split(',') || [];
      this.labelList = list?.map(item => {
        return { name: item };
      });
    },
    /**
     * @description: 获取标签列表
     * @param {*}
     * @return {*}
     */
    async getLabelInfo() {
      let res = await getLabelInfo(this.materialDetailsMessage?.id || '');
      res.code == 0 && (this.tagList = res.list);
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },
    // ----------------------------------------------基本信息--------------------------------------------
    //#region
    // 编辑、替换素材、添加素材（本地、素材库）、素材分析
    uploadMaterial(data) {
      // 只有素材的状态是待投放的素材可以被替换!!!!!!!!!!!!
      if (data.type === 'assets') {
        this.modalVisible = true;
      } else if (data.type === 'analyze') {
        this.$message.warning('跳转素材分析');
      } else if (data.type === 'update') {
        this.materialDetailsMessage.costStatus == '未使用' ? this.$emit('uploadMaterial', data) : this.$message.error('当前素材状态无法替换');
      } else {
        this.$emit('uploadMaterial', data);
      }
    },
    // 删除相似素材
    deleteMaterial(index) {
      const _this = this;
      Modal.confirm({
        title: '删除',
        content: '该信息删除后将不予保留，请谨慎操作！',
        onOk() {
          _this.similarMaterialList.splice(index, 1);
        },
        onCancel() {
        },
      });
    },
    // 获取人员信息
    async getPersonInfo() {
      const res = await getMaterialPersonInfoData(this.materialDetailsMessage.id);
      this.detailPersonInfo = res.vo;
      // eslint-disable-next-line vue/no-mutating-props
      this.materialDetailsMessage.channel = this.detailPersonInfo?.channel;
    },
    // 获取相似素材列表
    async getSimilarMaterialList() {
      this.similarMaterialList = [{}, {}, {}, {}, {}, {}];
    },
    //#endregion
    // ----------------------------------------------关联数据--------------------------------------------
    //#region
    /**
     * @description: 打开素材拒审理由弹窗
     * @param {*}
     * @return {*}
     */
    openDeniedTrialModal() {
      this.deniedTrialModalVisible = true;
    },
    /**
     * @description: 获取趋势图数据
     * @param {*}
     * @return {*}
     */
    async getTrendData() {
      if (!this.collapseKey) return;
      const params = {
        id: this.materialDetailsMessage.id + '',
        channel: this.collapseKey,
        indexOneKey: this.indexOneKey?.value,
        indexTwoKey: this.indexTwoKey?.value,
        startTime: this.postTime[0],
        endTime: this.postTime[1],
        timeLine: this.timeLine,
      };
      if (this.collapseKey != 'GDT') delete params.timeLine;
      const res = await getTrendData(params);

      let indexOneData = [];
      let indexTwoData = [];
      res.data?.forEach(item => {
        indexOneData.push([item.date, item.indexOneKey]);
        indexTwoData.push([item.date, item.indexTwoKey]);
      });
      const index1 = this.indexOneKey.label;
      const index2 = this.indexTwoKey.label;
      const data = {
        legend: { data: [index1, index2] },
        series1: { name: index1, data: indexOneData },
        series2: { name: index2, data: indexTwoData },
      };
      const options = echartsOptions.trend(data);
      this.options = options;
    },
    // 关联数据趋势图时间选择限制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    /** 口径下拉变化
     * @param {*}
     * @return {*}
     */
    onTJKJSelect(item, checked) {
      checked && (item = 'ACTIVE_TIME');
      this.timeLine = item;
      const selectedValue =
        this.tjkjList.find(tjkj => {
          return tjkj.key == item;
        })?.title || '转化回传激活';
      this.tjkjSelected = { selectedKey: item, selectedValue };
      this.getTrendData();
      //请求数据
    },
    // 投放时间改变
    onDatePlayRangeChange(date) {
      this.postTime = date;
      this.getAssocDetail();
    },
    /**
     * @description: 处理时间范围
     * @param {*}
     * @return {*}
     */
    formatDate(params) {
      if (this.baseFilter.ADUploadTIme && this.baseFilter.ADUploadTIme.length > 0) {
        params.advertiseStartDate = this.baseFilter.ADUploadTIme[0].format('YYYY-MM-DD');
        params.advertiseEndDate = this.baseFilter.ADUploadTIme[1].format('YYYY-MM-DD');
      }
      params.startDate = this.baseFilter.uploadTime?.[0].format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
      params.endDate = this.baseFilter.uploadTime?.[1].format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
    },
    // 折叠面板改变
    collapseChange(key) {
      this.indexOneKey = { value: 'cost', label: '消耗', key: 'cost' };
      this.indexTwoKey = { value: 'ctr', label: '点击率', key: 'ctr' };
      if (key) {
        this.mediaTableKey = this.tabList[key][0].key;
        this.getAssocDetail();
        this.currentCollapseKey = key;
      }
    },
    // 关联数据下表格的page改变、排序改变
    tableModalChange(pagination, filters, sorter) {
      this.tableModalPagination.page = pagination.current.toString();
      this.tableModalPagination.limit = pagination.pageSize.toString();
      this.tableModalPagination.pageSize = pagination.pageSize;
      this.tableModalSorter.orderType = sorter.field;
      if (Array.isArray(this.tableModalSorter.orderField) && sorter.field) {
        this.tableModalSorter.orderField = [];
        this.tableModalSorter.orderField.push(sorter.field);
      } else if (sorter.field) {
        this.tableModalSorter.orderField = [];
        this.tableModalSorter.orderField.push(sorter.field);
      }
      this.tableModalSorter.orderField = unique(this.tableModalSorter.orderField);
      this.tableModalSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';
      const onlyGetTableData = true;
      this.isUserClick = true;
      this.getAssocDetail(onlyGetTableData);
      this.isUserClick = false;
    },
    // 获取关联详细信息
    getAssocDetail(onlyGetTableData) {
      if (!this.isUserClick) {
        this.tableModalSorter = { order: 'desc', orderField: ['cost'] };
      }
      this.mediaTableData = [];
      if (this.tableLoading) {
        getAssociateDetailedDataCancel('cancel request');
      }
      let postData = {
        ...this.tableModalSorter,
        ...this.tableModalPagination,
        md5: this.materialDetailsMessage.md5,
        startTime: this.postTime[0],
        endTime: this.postTime[1],
        mediaChannel: this.collapseKey || 'GDT',
        moduleName: this.mediaTableKey.split('-')?.[0] || 'MTZH',
        type: this.type,
      };
      this.tableLoading = true;
      getAssociateDetailedData(postData).then(res => {
        this.mediaTableData = res.page?.list;
        this.tableLoading = false;
        this.tableModalPagination.total = res.page?.totalCount;
        this.tableModalPagination.current = res.page?.currPage;
        this.tableModalPagination.pageSize = res.page?.pageSize;
      });
      if (!onlyGetTableData) {
        this.$nextTick(() => {
          this.getTrendData();
        });
      }
    },
    /** 获取关联详情各渠道金额
     * @param {*} record 获取详情的列数据
     * @return {*}
     */
    getCostByMd5(record) {
      let postData = {
        md5: record.md5,
        startTime: this.filterCondition.startMaterialLaunchDate,
        endTime: this.filterCondition.endMaterialLaunchDate,
      };
      getCostByMd5(postData).then(res => {
        if (!this.amountShowDetail) {
          this.formatAmount(Array.isArray(res.list) ? res.list : [res.list]);
        }
        this.collapseList.forEach(item => {
          item.countCost = res.list[item.key] || 0;
        });
      });
    },
    /** 格式化数组数据
     * @param {Array} arr 需要格式化的数据
     * @return {*}
     */
    formatAmount(arr) {
      arr?.forEach(item => {
        Object.keys(item)?.forEach(key => {
          if (isNumber(item[key] - 0) && key.indexOf('Code') === -1 && key !== 'id') {
            item[key] = addChineseUnit(item[key] - 0);
          }
        });
      });
    },
    // 切换基本信息和关联数据
    onDetailTabChange(key) {
      if (key === '2') {
        this.videoPlayerPlay = false;
        if (!this.mediaTableData.length) {
          this.getAssocDetail();
        }
      } else {
        this.videoPlayerPlay = true;
      }
    },
    //#endregion
  },
};
</script>

<style lang='less'>
.form_wrapper .ant-row {
  font-size: 14px;
}

//操作a标签颜色
.alink-color a {
  font-size: 14px;
  color: #555555; /* 普通颜色 */
}

.alink-color a:hover {
  color: #2f70f4; /* 悬停颜色 */
}
.form_wrapper {
  margin-top: 15px;
  padding: 14px 20px;
  background: rgba(251, 251, 251, 1);

  .denied-trial-wrapper {
    margin-bottom: 16px;

    .denied-trial {
      padding-right: 15px;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .module_title {
    border-left: 4px solid #2f70f4;
    text-indent: 12px;
    line-height: 12px;
    margin: 24px 0 16px;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>

