/*
 * @Author: chenxing
 * @Date: 2021-04-25 12:01:37
 * @LastEditors: zhangruifeng
 * @LastEditTime: 2021-06-25 11:02:51
 */
import axios from '@/utils/axios';

const apiUrl = {
    scriptList: '/material/materialData/getMaterialSingeCostData',
    saveScript: '/material/materialMake/saveOrUpdateByFileMaker',
    calcScriptCost: '/material/materialData/calculateByRangeDay',
    getActorListByDate: '/material/actorTime/getListByDay',
    deleteScript: '/material/materialMake/deleteByMakeId',
    getActorRelationList: '/material/materialData/materialMakeActorRelation',
    scriptNameList: '/material/materialMake/getList'
};

//获取脚本列表数据
export function getScriptListData(params) {
    return axios.post(apiUrl.scriptList, params);
}
//获取脚本名称列表数据
export function getScriptNameListData() {
    return axios.post(apiUrl.scriptNameList, {});
}


//保存脚本
export function saveScriptData(params) {
    return axios.post(apiUrl.saveScript, params);
}

//计算脚本花费
export function calcScriptCost(params) {
    return axios.post(apiUrl.calcScriptCost, params);
}

//获取制定日期的演员列表
export function getActorListDataByDate(params) {
    return axios.post(apiUrl.getActorListByDate, params);
}

//删除脚本
export function deleteScriptData(makeCode) {
    return axios.get(apiUrl.deleteScript + `/${makeCode}`);
}

//获取参演艺人
export function getActorRelationListData(makeId) {
    return axios.get(apiUrl.getActorRelationList + `/${makeId}`);
}
//获取脚本信息
export function getMaterialSingeCostDataAPI(params) {
    return axios.post(apiUrl.scriptList ,params);
}
