/*
 * @Author: Yran
 * @Date: 2021-05-21 14:57:16
 * @LastEditors: Yran
 * @LastEditTime: 2022-02-10 18:00:25
 */
import axios from '@/utils/axios';

const apiUrl = {
  /**********************产品列表**************************************/
  getProductList: '/sys/project/getProjectPage',
  getAllProductList: '/sys/project/getList',
  saveAddProduct: '/sys/project/saveProject',
  saveUpdateProduct: '/sys/project/updateProject',
  projectId: 'sys/project/',

  /**********************客户列表**************************************/
  getAllCustomerList: '/sys/customers/getList',

  /**********************媒体列表**************************************/
  getMediaList: '/sys/project/getProjectMediaInfo',
  getAllMediaAccountList: '/sys/project/getProjectMdAccountInfo',
  deleteProduct: '/sys/project/delete',
  industryList: '/sys/dict/industry',
};

// 获取产品列表
export function getProductList(params) {
  return axios.post(apiUrl.getProductList, params);
}
// 获取产品列表详情
export function getProjectInfoById(projectCode) {
  return axios.post(apiUrl.projectId + projectCode);
}

// 获取所有产品列表
export function getAllProductList() {
  return axios.get(apiUrl.getAllProductList);
}

// 新增产品
export function saveAddProduct(params) {
  return axios.post(apiUrl.saveAddProduct, params);
}

// 编辑产品
export function saveUpdateProduct(params) {
  return axios.post(apiUrl.saveUpdateProduct, params);
}

// 获取所有客户名称列表
export function getAllCustomerList() {
  return axios.get(apiUrl.getAllCustomerList);
}

// 根据产品获取媒体列表
export function getMediaList(params) {
  return axios.post(apiUrl.getMediaList, params);
}

// 获取所有媒体账户列表
export function getAllMediaAccountList(params) {
  return axios.post(apiUrl.getAllMediaAccountList, params);
}

// 删除产品
export function deleteProduct(params) {
  return axios.post(apiUrl.deleteProduct, params);
}
// 获取所属行业列表树形
export function getIndustryList() {
  return axios.get(apiUrl.industryList);
}
