/*
 * @Author: chenxing
 * @Date: 2021-08-19 11:38:43
 * @LastEditors: chenxing
 * @LastEditTime: 2021-08-19 16:03:16
 */

import OSS from 'ali-oss';
let ossConfig = {
    region: 'oss-cn-shanghai',
    accessKeyId: 'LTAI5t75D8WpgAbsopqcTDHZ',
    accessKeySecret: 'Ryat8Y7lBukUZSXgVq49zk1rGQ6gAX',
    bucket: 'luming-material',
};

let oss = new OSS(ossConfig);

// 获取指定资源下载链接
export function getOpDownloadUrl(ossObjectName, saveFileName) {
    // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
    const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(saveFileName)}`
    };
    // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
    return oss.signatureUrl(ossObjectName, { response });
}

export async function getOpUrl(ossObjectName, options = {}) {
    return oss.signatureUrl(ossObjectName, options);
}