<!--自定义a-table组件，使用时，用v-if来显示某列的数据-->
<template>
  <a-table :data-source="dataSource" class="table-with-statistic" :scroll="tableScroll">
    <template v-for="column in columnList" :key="column.dataIndex">
      <a-table-column :fixed="column.fixed" :width="getColumnWidth(column)" :data-index="column.dataIndex"
                      v-bind="column"
                      class="column48">
        <template #default="{ record, text, index }">
          <div class="td-content">
            <slot name="default" v-bind="{ record, text, index, column }">
            </slot>
          </div>
        </template>
      </a-table-column>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'TableWithStatistic',
  components: {},
  props: {
    // 表头数据
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    // 表格数据
    dataSource: {
      type: Array,
      default() {
        return [];
      },
    },
    columnWidthEqualTitle: {
      type: Boolean,
      default: false,
    },
    // 滚动配置
    scroll: {
      type: Object,
      default() {
        return {
          x: 1000, //x轴滚动宽度 number
          y: 200, //y轴滚动高度 number
          otherHeight: 0, //额外的高度   number
          interval: 600, //容器变化时回调触发频率
          wrapperId: null, //表格外部容器id名或    string
        };
      },
    },
  },
  data() {
    return {
      scrollHeight: this.scroll.y || 200,
    };
  },
  watch: {
    // 生成表格高度
    columnList(val) {
      if (val?.length) {
        this.getTableHeight();
      }
    },
  },
  computed: {
    // 表头数据
    columnList() {
      // 如果全是固定列就取消固定列和宽度限制
      if (this.columns.every(column => column.fixed)) {
        return this.columns.map(column => {
          const obj = { ...column };
          delete obj.fixed;
          delete obj.width;
          return obj;
        });
      } else {
        // 去除重复列数据
        const arr = [];
        const set = new Set();
        this.columns.forEach((column, index) => {
          if (!set.has(column.dataIndex)) {
            arr.push(column);
          }
          set.add(column.dataIndex);
        });
        return arr;
      }
    },
    // 滚动配置
    tableScroll() {
      return {
        x: this.scroll.x,
        y: this.scrollHeight,
      };
    },
  },
  methods: {
    // 处理表格高度
    getTableHeight() {
      const { wrapperId, otherHeight = 0 } = this.scroll;
      let wrapper;
      if (!wrapperId) {
        return;
      } else {
        wrapper = document.getElementById(wrapperId);
      }
      let scrollHeight = 200;
      let timer = null;
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            const wrapperHeight = window.getComputedStyle(wrapper).height; //表格容器高度
            const header = wrapper.querySelector('.ant-table-header');
            const headerHeight = (header && window.getComputedStyle(header)?.height) || '40px'; //表头高度
            scrollHeight = parseFloat(wrapperHeight) - (parseFloat(headerHeight) < 30 ? 40 : parseFloat(headerHeight)) - otherHeight;
            this.scrollHeight = parseInt(scrollHeight);
          }, this.scroll.interval ?? 600);
        });
      });
      observer.observe(wrapper);
    },
    // 处理列宽
    getColumnWidth(column) {
      return column.width;
    },
  },
};
</script>

<style lang="less">
.table-with-statistic {
  .column48 {
    height: 48px;

    .ant-table-header-column {
      height: 31px;

      & > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .ant-table-thead {
    .ant-table-column-sorter {
      transform: translateY(-2px);
    }

    .statistic-th {
      background: white !important;
      font-weight: bold;
    }
  }

  .td-content {
    .textHide-2rows {
      line-height: 16px;
    }
  }
}
</style>
