<!--
 * @Author: Yran
 * @Date: 2021-09-28 14:24:51
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-29 14:54:29
-->
<template>
  <a-modal :visible="visible" class="change_make_code" :mask-style="{ zIndex: 1001 }" :width="1432" title="选择脚本" centered :footer="null" @cancel="handleCancel">
    <div class="modal_filter flexJustifyBetween">
      <a-form class="flexAlignCenter">
        <a-form-item class="mb0" name="projectCode">
          <a-select class="mr10" style="width: 200px" v-model:value="makeCodeFilterOptions.projectCode" placeholder="请选择产品" v-bind="$filterOptions" allow-clear :disabled="!!projectCode">
            <template #notFoundContent>
              <a-empty :image="simpleImage">
                <template #description>
                  <span>暂无数据</span>
                </template>
              </a-empty>
            </template>
            <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="mb0" name="makeCode">
          <div style="display: flex">
            <a-select class="mr10" style="width: 200px" v-model:value="makeCodeFilterOptions.makeCode" placeholder="请选择脚本" v-bind="$filterOptions" dropdown-class-name="select-options" allow-clear>
              <template #notFoundContent>
                <a-empty :image="simpleImage">
                  <template #description>
                    <span>暂无数据</span>
                  </template>
                </a-empty>
              </template>
              <a-select-option v-for="option in getScriptByTiProduct" :key="option.makeCode" :value="option.makeCode" :label="option.makeName">{{ option.makeName }}</a-select-option>
            </a-select>
          </div>
        </a-form-item>
        <a-form-item class="mb0">
          <a-select class="mr10" style="width: 200px" v-model:value="makeCodeFilterOptions.mouthPlay" placeholder="请选择脚本类型" v-bind="$filterOptions" allow-clear>
            <a-select-option v-for="option in mouthplayOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="mb0" :wrapper-col="{ span: 16 }" name="screenwriterName">
          <a-select class="mr10" style="width: 200px" v-model:value="makeCodeFilterOptions.screenwriterName" placeholder="请选择编导" v-bind="$filterOptions" dropdown-class-name="select-options" allow-clear>
            <template #notFoundContent>
              <a-empty :image="simpleImage">
                <template #description>
                  <span>暂无数据</span>
                </template>
              </a-empty>
            </template>
            <a-select-option v-for="option in getAllDirectorList" :key="option.userId" :value="option.userCode" :label="option.userCode">{{ option.userCode }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="mb0">
          <a-range-picker v-model:value="makeCodeFilterOptions.uploadTime" style="width: 298px" :placeholder="['开始日期', '结束日期']" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" />
        </a-form-item>
      </a-form>
      <div class="filter-buttons flexBetweenCenter">
        <a-button class="btn mr10 filter-btn" type="primary" @click="getMakeListData" :disabled="makeCodeLoading">筛选</a-button>
        <a-button class="btn cancen-btn" @click="onResetBtnClick" :disabled="makeCodeLoading">重置</a-button>
      </div>
    </div>
    <div class="table_wrapper">
      <a-table size="small" style="min-height: 600px" :data-source="makeCodeData.dataSource" :pagination="false" :scroll="{ y: 600 }" :columns="makeCodeData.columns" :row-key="record => record.make_code" :loading="makeCodeLoading" bordered :row-selection="rowSelection" :custom-row="(record, index) => rowClick(record, index)">
        <template #uploadTime="{ record }">
          {{ record.upload_time ? record.upload_time.substr(0, 10) : '_ _' }}
        </template>
        <template #mouthPlay="{ record }">
          {{ record.mouth_play ? columnMapValue(mouthplayOptions, record.mouth_play, 'value', 'label') : '_ _' }}
        </template>
      </a-table>
      <div class="table_toolbar flexBetweenCenter pt20">
        <a-pagination v-bind="tablePagination" show-less-items @change="tableChange" @showSizeChange="tableChange" />
        <div class="filter-buttons flexBetweenCenter">
          <a-button class="btn cancen-btn mr10" @click="handleCancel">取消</a-button>
          <a-button class="btn filter-btn" type="primary" @click="handleOK">确定</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import moment from 'moment';
import { Empty } from 'ant-design-vue';
import { getMakeCodeList } from '@/api/material/material.js';
export default {
  emits: ['update:visible', 'changeMakeCode'],
  props: {
    // 弹窗开关
    visible: {
      type: Boolean,
      default: true,
    },
    // 素材关联产品
    projectCode: {
      type: String,
      default: '',
    },
    // 素材关联产品列表
    projectListOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 脚本列表
    getScriptByTiProduct: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 脚本类型列表
    mouthplayOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 所属组列表
    getAllDirectorList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 选中的行key
      selectedRowKeys: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // 脚本列表数据
      makeCodeData: {
        columns: [
          {
            title: '时间',
            dataIndex: 'upload_time',
            key: 'upload_time',
            slots: {
              customRender: 'uploadTime',
            },
          },
          {
            title: '事业部',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '产品名称',
            dataIndex: 'of_project_name',
            key: 'of_project_name',
          },
          {
            title: '脚本名称',
            dataIndex: 'make_name',
            key: 'make_name',
          },
          {
            title: '参演艺人',
            dataIndex: 'actor_name',
            key: 'actor_name',
          },
          {
            title: '成本（元/条）',
            dataIndex: 'actors_cost',
            key: 'actors_cost',
          },
          {
            title: '摄像',
            dataIndex: 'shooting_name',
            key: 'shooting_name',
          },
          {
            title: '编导',
            dataIndex: 'screenwriter_name',
            key: 'screenwriter_name',
          },
          {
            title: '脚本类型',
            dataIndex: 'mouth_play',
            key: 'mouth_play',
            slots: {
              customRender: 'mouthPlay',
            },
          },
        ],
      },
      // 脚本筛选数据
      makeCodeFilterOptions: {},
      // 表格分页数据
      tablePagination: {
        limit: 30,
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      // 表格加载loading
      makeCodeLoading: false,
      // 时间格式化
      dateRangeFormat: 'YYYY-MM-DD',
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: selectedRowKeys => {
          if (selectedRowKeys) this.selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
        },
        columnTitle: ' ',
      };
    },
  },
  watch: {
    async visible(val) {
      if (val) {
        this.makeCodeFilterOptions.projectCode = this.projectCode || null;
        this.getMakeListData();
      }
    },
  },
  mounted() {},
  methods: {
    /** 行点击
     * @param {*} record
     * @param {*} index
     * @return {*}
     */
    rowClick(record, index) {
      return {
        onClick: event => {
          this.selectedRowKeys = [record.make_code];
        },
      };
    },
    /** 禁用时间
     * @param {*} current
     * @return {*}
     */
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    tableChange(page, pageSize) {
      this.tablePagination.current = page;
      this.tablePagination.pageSize = pageSize;
      this.tablePagination.limit = pageSize;
      this.getMakeListData();
    },

    /** 获取关联脚本列表
     * @param {*}
     * @return {*}
     */
    async getMakeListData() {
      this.makeCodeLoading = true;
      let postData = { ...this.makeCodeFilterOptions, page: this.tablePagination?.current, limit: this.tablePagination.limit };
      if (postData.uploadTime && postData.uploadTime.length > 0) {
        postData.startUploadTime = postData.uploadTime[0].format(this.dateRangeFormat);
        postData.endUploadTime = postData.uploadTime[1].format(this.dateRangeFormat);
      }
      delete postData.uploadTime;
      let res = await getMakeCodeList(postData);
      if (res.code === 0) {
        this.makeCodeData.dataSource = res.data?.list;
        this.tablePagination.current = res.data?.currPage;
        this.tablePagination.pageSize = res.data?.pageSize;
        this.tablePagination.limit = res.data?.pageSize;
        this.tablePagination.total = res.data?.totalCount;
      }
      this.makeCodeLoading = false;
    },
    /** 重置筛选
     * @param {*}
     * @return {*}
     */
    onResetBtnClick() {
      this.makeCodeFilterOptions = {
        projectCode: this.projectCode || null,
      };
      this.getMakeListData();
    },
    /** 确认选择
     * @param {*}
     * @return {*}
     */
    handleOK() {
      this.$emit('changeMakeCode', this.selectedRowKeys);
      this.handleCancel();
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    handleCancel() {
      this.makeCodeFilterOptions = {};
      this.selectedRowKeys = [];
      this.$emit('update:visible', false);
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMapValue(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt?.[text] || '';
      }
    },
  },
};
</script>
<style lang="less" scoped>
.change_make_code {
  .ant-modal-body {
    padding: 12px 24px;
  }
}
.table_wrapper {
  padding: 12px 0;
}
</style>