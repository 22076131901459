/*
 * @Author: chenxing
 * @Date: 2021-03-23 15:22:39
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-01 17:31:03
 */
import axios, { lydata_prefix } from '@/utils/axios';
import _axios from 'axios';



const apiUrl = {
  reportInfo: '/report/reportInfo/getReportInfo',
  mediaAccount: '/report/reportInfo/getMedaByChannelType',
  operatorList: '/sys/user/getOperatorByDeptId',
  exportExcel: '/report/reportInfo/exportExcel',
  aggregation: '/report/hour/aggregation',
  noAggregation: '/report/hour',
  getRefreshTime: '/redisValue/getDate/',
  getDataCenterAllConfigQuota: '/temp/tempCustomConfig/getByConfigKey/DATA_CENTER',
  getAllTempList: '/temp/tempUserCustom/getConfigTypeAndTempNames',
  getChooseTemp: '/temp/tempUserCustom/getTempDetail',
  getTrendData: '/report/reportInfo/getTrend',

  getCustomerReportTableList:'/tias-report/customer_report/list',
  updateReportsStatus: '/tias-report/customer_report/subscribe',
  getCustomerReportDetailById:'/tias-report/customer_report/getById',
  getFeishuMembers: '/tias-report/customer_report/feishu_members',
  getFeishuChats: '/tias-report/customer_report/feishu_chats',
  addSubscribeReport: '/tias-report/customer_report/add',
  editSubscribeReport: '/tias-report/customer_report/edit',
  deleteReports: '/tias-report/customer_report/del',
  doReportsAPI: '/tias-report/customer_report/do_task',
};

export function getCustomerReportDetailById(id) {
  return axios.get(apiUrl.getCustomerReportDetailById+"?id="+id);
}
export function updateReportsStatus(params) {
  return axios.get(apiUrl.updateReportsStatus+"?id="+params.id+"&subscribeType="+params.subscribeType);
}

export function getCustomerReportTableList(param) {
  return axios.post(apiUrl.getCustomerReportTableList, param);
}

export function getAdvertiserReportData(param) {
  return axios.post(apiUrl.reportInfo, param);
}

export function getMediaAccountListData(param) {
  return axios.post(apiUrl.mediaAccount, param);
}

export function getOperatorDataByDeptId(param) {
  return axios.post(apiUrl.operatorList, param);
}

export function exportExcel(param) {
  return axios.post(apiUrl.exportExcel, param, {
    timeout: 30 * 1000,
    withCredentials: true,
    responseType: 'blob',
  });
}

export function getAggregation(params) {
  return axios.post(lydata_prefix + apiUrl.aggregation, params);
}
export function getNoAggregation(params) {
  return axios.post(lydata_prefix + apiUrl.noAggregation, params);
}
export function getRefreshTime(redisKey) {
  return axios.get(apiUrl.getRefreshTime + redisKey + ':');
}
export function getDataCenterAllConfigQuota() {
  return axios.get(apiUrl.getDataCenterAllConfigQuota);
}
export function getDataCenterUserConfigTempList(configType, mediaChannel) {
  console.log(apiUrl.getAllTempList + `/${configType}/${mediaChannel}`);
  return axios.get(apiUrl.getAllTempList + `/${configType}/${mediaChannel}`);
}
export function getChooseTemp(mediaChannel,tempName) {
  return axios.get(apiUrl.getChooseTemp + `/${mediaChannel}/${tempName}`);
}
export function getTrendData(params) {
  return axios.post(apiUrl.getTrendData, params);
}

export function getFeishuMembers() {
  return axios.get(apiUrl.getFeishuMembers);
}

export function getFeishuChats() {
  return axios.post(apiUrl.getFeishuChats);
}

export function addSubscribeReport(params) {
  return axios.post(apiUrl.addSubscribeReport, params);
}

export function editSubscribeReport(params) {
  return axios.post(apiUrl.editSubscribeReport, params);
}

export function deleteReports(params){
  return axios.get(apiUrl.deleteReports + `?id=${params}`);
}

export function doReportsAPI(params){
  return axios.get(apiUrl.doReportsAPI + `?id=${params}`);
}