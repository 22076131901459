<!--
 * @Author: chenxing
 * @Date: 2021-04-15 13:54:54
 * @LastEditors: chenxing
 * @LastEditTime: 2021-12-22 17:32:27
-->
<template>
  <video controls :id="id" :style="style" :autoplay="autoplay" :controlslist="menuList" ref="video">
    <source :src="vsrc" :type="type" />
  </video>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'videoPlayer',
    },
    src: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'video/mp4',
    },
    // 默认宽高比16:9
    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 197,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    videoStyle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    play: {
      type: Boolean,
      default: true,
    },
    controlslist: {
      type: Array,
      default() {
        return ['nodownload'];
      },
    },
  },
  emits: ['playStatusChange'],
  data() {
    return {
      vsrc: '',
    };
  },
  computed: {
    style() {
      const style = {
        width: this.videoStyle.width || this.width + 'px',
        height: this.videoStyle.height || this.height + 'px',
      };
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.vsrc = this.src;
      return style;
    },
    menuList() {
      return this.controlslist.join(' ');
    },
  },
  watch: {
    play(newVal) {
      if (newVal) {
        const parent = this.$refs.video.parentNode;
        if (parent && getComputedStyle(parent).visibility) {
          this.$refs.video.play();
        }
      } else {
        const parent = this.$refs.video.parentNode;
        if (parent && getComputedStyle(parent).visibility) {
          this.$refs.video.pause();
        }
      }
    },
  },
  methods: {},
  beforeUnmount() {
    this.$refs.video.autoplay = false;
  },
  mounted() {
    //  play：播放监听
    this.$refs.video.addEventListener('play', e => {
      this.$emit('playStatusChange', true);
    });

    //  pause：暂停监听
    this.$refs.video.addEventListener('pause', e => {
      this.$emit('playStatusChange', false);
    });

    var scrollTop = document.scrollingElement.scrollTop;

    var video = this.$refs.video;
    function onFullScreenChange() {
      if (document.fullscreenElement && document.fullscreenElement === video) {
        return;
      } else {
        document.scrollingElement.scrollTop = scrollTop || 0;
        setTimeout(function () {
          document.scrollingElement.scrollTop = scrollTop || 0;
        }, 50);
      }
    }
    document.addEventListener('fullscreenchange', onFullScreenChange);
    document.addEventListener('webkitfullscreenchange', onFullScreenChange);
    document.addEventListener('mozfullscreenchange', onFullScreenChange);
  },
};
</script>

<style>
</style>