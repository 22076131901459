<!--
 * @Author: Yran
 * @Date: 2021-09-23 17:29:01
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-27 16:50:02
-->
<template>
  <a-modal :visible="visible" class="edit_material_title" :mask-style="{ zIndex: 1001 }" title="素材名称编辑" centered @ok="handleOK" @cancel="handleCancel">
    <a-row class="w100">
      <a-col :span="24">
        <a-form-item label="原素材名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"> <a-input style="width: 100%" v-model:value="materialNames.oldName" :disabled="true"></a-input> </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-form-item label="新素材名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-input style="width: 100%" v-model:value="materialNames.newName" placeholder="请输入新的素材名称"></a-input>
        </a-form-item>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
export default {
  emits: ['update:visible', 'editTitle'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    materialName: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      materialNames: {},
    };
  },
  watch: {
    materialName(newVal) {
      this.materialNames = newVal;
    },
  },
  mounted() {
    this.materialNames = this.materialName;
  },
  methods: {
    handleOK() {
      this.materialNames.newName = this.materialNames.newName.trim();
      if (this.materialNames.newName) {
        this.$emit('editTitle', this.materialNames.newName);
      } else {
        this.$message.error('素材名称不能为空字符，请输入新的素材名称');
      }
    },
    handleCancel() {
      this.materialNames.newName = null;
      this.$emit('update:visible', false);
    },
  },
};
</script>