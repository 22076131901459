<!--
 * @Author: LiuXin
 * @Date: 2021-12-14 17:11:33
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-06 16:23:02
-->
<template>
  <a-table :data-source="dataSource" class="table-with-statistic" :scroll="tableScroll">
    <template v-for="column in columnList" :key="column.dataIndex">
      <a-table-column-group v-bind="column" :width="getColumnWidth(column)" class="column48">
        <a-table-column :fixed="column.fixed" :width="getColumnWidth(column)" :data-index="column.dataIndex" class="column48 statistic-th">
          <template #title>
            <span>{{ statistic[column.dataIndex] ?? '-' }}</span>
          </template>
          <template #default="{ record, text, index }">
            <div class="td-content">
              <slot name="default" v-bind="{ record, text, index, column }">
                <span class="textHide-2rows" :title="text">{{ getTextRate(text,column)}}</span>
                <span class="textHide-2rows"  v-show="needShow(column)" :title="column.dataIndex">{{ getRate(column,record) }}</span>
              </slot>
            </div>
          </template>
        </a-table-column>
      </a-table-column-group>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'TableWithStatistic',
  components: {},
  props: {

    //转换列
    dataIndexList:{
      type: Array,
      default() {
        return [
          'play_100_feed_break',
          'play_75_feed_break',
          'play_50_feed_break',
          'play_25_feed_break',
          'video_outer_play100_count',
          'video_outer_play75_count',
          'video_outer_play50_count',
          'video_outer_play25_count'
        ];
      },
    },
    // 表头数据
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    // 表格数据
    dataSource: {
      type: Array,
      default() {
        return [];
      },
    },
    // 统计行数据
    statistic: {
      type: Object,
      default() {
        return {};
      },
    },
    columnWidthEqualTitle: {
      type: Boolean,
      default: false,
    },
    // 滚动配置
    scroll: {
      type: Object,
      default() {
        return {
          x: 1000, //x轴滚动宽度 number
          y: 200, //y轴滚动高度 number
          otherHeight: 0, //额外的高度   number
          interval: 600, //容器变化时回调触发频率
          wrapperId: null, //表格外部容器id名或    string
        };
      },
    },
  },
  data() {
    return {
      scrollHeight: this.scroll.y || 200,
    };
  },
  watch: {
    // 生成表格高度
    columnList(val) {
      if (val?.length) {
        this.getTableHeight();
      }
    },
  },
  computed: {
    // 表头数据
    columnList() {
      // 如果全是固定列就取消固定列和宽度限制
      if (this.columns.every(column => column.fixed)) {
        return this.columns.map(column => {
          const obj = { ...column };
          delete obj.fixed;
          delete obj.width;
          return obj;
        });
      } else {
        // 去除重复列数据
        const arr = [];
        const set = new Set();
        this.columns.forEach((column, index) => {
          if (!set.has(column.dataIndex)) {
            arr.push(column);
          }
          set.add(column.dataIndex);
        });
        return arr;
      }
    },
    // 滚动配置
    tableScroll() {
      return {
        x: this.scroll.x,
        y: this.scrollHeight,
      };
    },
  },
  methods: {
    // 处理表格高度
    getTableHeight() {
      const { wrapperId, otherHeight = 0 } = this.scroll;
      let wrapper;
      if (!wrapperId) {
        return;
      } else {
        wrapper = document.getElementById(wrapperId);
      }
      let scrollHeight = 200;
      let timer = null;
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            const wrapperHeight = window.getComputedStyle(wrapper).height; //表格容器高度
            const header = wrapper.querySelector('.ant-table-header');
            const headerHeight = (header && window.getComputedStyle(header)?.height) || '40px'; //表头高度
            scrollHeight = parseFloat(wrapperHeight) - (parseFloat(headerHeight) < 30 ? 40 : parseFloat(headerHeight)) - otherHeight;
            this.scrollHeight = parseInt(scrollHeight);
          }, this.scroll.interval ?? 600);
        });
      });
      observer.observe(wrapper);
    },
    needShow(column){
      var showData = false;
      if(column){
        for (let dataIndexListElement of this.dataIndexList) {
            if(dataIndexListElement === column.dataIndex ){
              showData = true ;
              break;
            }
        }
      }
      return showData ;
    },
    getTextRate(text,column){
      if(!text){
        return '-';
      }
      if(column && column.dataIndex && text !== '-' && text !== 0 ){
        if(column.dataIndex.indexOf("rate") !== -1 || column.dataIndex.indexOf("ratio") !== -1){
          return text + "%";
        }
        if( column.dataIndex.indexOf("ctr") !== -1 ){
          return text + "%";
        }
      }
      return text ;
    },
    getRate(column,record){
      var souceVal =  record['_'+column.dataIndex];
      if(!this.needShow(column) || !souceVal){
        return ;
      }
      var total  = 1 ;
      if(record.channel == 'TT'){
        total = record['_total_play'];

      }else if(record.channel == 'GDT'){
        total = record['_video_outer_play_count'];
      }

      if(total && souceVal && total !== 0){
        var cellRate = souceVal / total * 100;
        var str =  cellRate.toString();
        var text = str.substr(0,str.indexOf(".")+4);
        if(!text){
          return '-';
        }
        return text + "%" ;
      }
      return '-' ;
    },
    // 处理列宽
    getColumnWidth(column) {
      // if (this.columnWidthEqualTitle && !column.width) {
      //   if (column.title == this.columnList.slice(-1)?.[0]?.title) {
      //     return undefined;
      //   }
      //   const padding = 8 * 2;
      //   const textWidth = column.width || column.title.length * 12;
      //   const sortWdith = column.sorter ? 20 : 0;
      //   return padding + textWidth + sortWdith;
      // }
      return column.width;
    },
  },
};
</script>

<style lang="less">
.table-with-statistic {
  .column48 {
    height: 48px;
    .ant-table-header-column {
      height: 31px;
      & > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .ant-table-thead {
    .ant-table-column-sorter {
      transform: translateY(-2px);
    }
    .statistic-th {
      background: white !important;
      font-weight: bold;
    }
  }
  .td-content {
    .textHide-2rows {
      line-height: 16px;
    }
  }
}
</style>
