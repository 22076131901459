<template>
  <a-modal :width="1200" @cancel="$emit('update:visible')" @ok="handleOk" class="add_similar_modal" :visible="visible" :ok-button-props="{ loading: btnLoading }" :mask-closable="false" title="添加相似素材-素材库">
    <div class="add_similar_wrapper w100">
      <a-form :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
        <a-row class="filter_row">
          <a-col :span="5">
            <a-form-item required>
              <a-select allow-clear v-if="mode === 'IMAGE'" v-model:value="filter.materialChannel" :options="materialChannelOptions" placeholder="请选择素材类型" />
              <a-select allow-clear v-else v-model:value="filter.materialChannel" placeholder="请选择素材类型">
                <a-select-opt-group label="内部">
                  <a-select-option key="QJJ" value="QJJ">情景剧</a-select-option>
                  <a-select-option key="YSJJ" value="YSJJ">剪辑</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="外部">
                  <a-select-option key="CG" value="CG">采购</a-select-option>
                  <a-select-option key="KHTG" value="KHTG">客户提供</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item required>
              <a-select v-model:value="filter.ofProjectCode" placeholder="请选择产品" v-bind="$filterOptions" allow-clear @change="getMaterialList">
                <a-select-option v-for="option in projectListOptions" :key="option.projectCode" :label="option.projectName">{{ option.projectName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item required>
              <a-input placeholder="请输入查询关键字" v-model:value="filter.keyword">
                <template #prefix> <SearchOutlined @click="onSearch" class="pointer" /> </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="5" :offset="4">
            <a-form-item required>
              <a-space>
                <a-checkbox @change="getMaterialList" v-model:checked="filter.uploadByMe">仅显示本人上传</a-checkbox>
                <span style="color: rgba(153, 153, 153, 1)">已选 {{ checkedNum }} 个</span>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="scroll_wrapper w100">
        <ul class="assets_material_list w100">
          <li v-for="(item, index) in materialList" :key="item" class="material_item">
            <div class="source_wrapper w100" @click="item.checked = !item.checked">
              <img src="https://img1.baidu.com/it/u=707948835,1763531971&fm=26&fmt=auto&gp=0.jpg" alt="" />
            </div>
            <a-checkbox class="material_name" v-model:checked="item.checked"> 20210719_叮咚买菜_78_榴莲_ 720.mp4 {{ index }}</a-checkbox>
            <a-checkbox class="material_tag"> 11原素材的二次创作 </a-checkbox>
          </li>
        </ul>
      </div>
    </div>
    <div v-pre>
      <span>1111 <a href="233432">----12324235435435</a></span>
    </div>
    <p style="text-align: center; width: 100%; height: 20px"><LoadingOutlined v-show="atBottom" style="font-size: 20px" /></p>
  </a-modal>
</template>

<script>
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import lodash from 'lodash';
export default {
  name: 'AddSimilarMaterial',
  components: { SearchOutlined, LoadingOutlined },
  emits: ['update:visible'],
  props: {
    mode: { type: String, default: 'IMAGE' },
    visible: { type: Boolean, default: false },
  },
  inject: ['getProjectList', 'getMaterialDetail'],
  data() {
    return {
      filter: {
        materialChannel: undefined,
        ofProjectCode: undefined,
        keyword: undefined,
        uploadByMe: true,
      },
      projectListOptions: [], //关联产品下拉列表
      materialList: [],
      materialList_copy: [],
      checkedList: [],
      btnLoading: false,
      atBottom: false,
      selectedCount: 0,
      materialDetail: {}, //当前素材详情
      // 图片素材类型
      materialChannelOptions: [
        { label: '内部制作', value: 'NBZZ' },
        { label: '外部采购', value: 'WBCG' },
        { label: '客户提供', value: 'KHTG' },
      ],
    };
  },
  computed: {
    // 选中的素材数量
    checkedNum() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.checkedList = this.materialList.filter(item => item.checked);
      return this.checkedList.length;
    },
    // 素材类型
    materailType() {
      if (mode === 'IMAGE') {
        return {};
      } else {
        return {};
      }
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        window.removeEventListener('mousewheel', this.scrollToBottom);
        this.materialList = null;
      } else {
        this.filter.ofProjectCode = this.materialDetail.ofProjectCode;
        this.filter.materialChannel = this.materialDetail.materialChannel;
        this.filter.keyword = undefined;
        this.filter.uploadByMe = true;
        this.materialList = lodash.cloneDeep(this.materialList_copy);
      }
    },
  },
  async mounted() {
    await this.getMaterialList(); //获取素材料表
    window.addEventListener('mousewheel', this.scrollToBottom);
    this.projectListOptions = this.getProjectList();
    this.materialDetail = this.getMaterialDetail();
  },

  methods: {
    // 获取相似素材列表
    async getMaterialList() {
      for (let index = 0; index < 20; index++) {
        this.materialList.push({ checked: false });
      }
      this.materialList_copy = lodash.cloneDeep(this.materialList);
    },
    // 点击确认
    handleOk() {
      const param = this.materialList.filter(item => item.checked);
      this.btnLoading = true;
      setTimeout(() => {
        this.$message('添加成功');
        this.$emit('update:visible', false);
        this.btnLoading = false;
      }, 1000);
    },
    // 根据关键字搜索素材
    onSearch(val) {},
    // 滚动条滑动到底部时
    scrollToBottom() {
      if (this.atBottom) {
        return;
      }
      const listWrapper = document.getElementsByClassName('assets_material_list')[0];
      const scrollWrapper = document.getElementsByClassName('scroll_wrapper')[0];
      if (!scrollWrapper) {
        return;
      }
      scrollWrapper.onscroll = event => {
        //禁止事件默认行为（此处禁止鼠标滚轮行为关联到"屏幕滚动条上下移动"行为）
        event.preventDefault();
        //scrollTop就是触发滚轮事件时滚轮的高度
        var scrollTop = scrollWrapper.scrollTop;
        //变量wrapperHeight是可视区的高度
        var wrapperHeight = scrollWrapper.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = listWrapper.clientHeight;
        //判断滚动条是否到底部
        if (scrollTop + wrapperHeight === scrollHeight) {
          //加载数据
          this.atBottom = true;
          setTimeout(() => {
            for (let index = 0; index < 20; index++) {
              this.materialList.push({});
            }
            this.atBottom = false;
          }, 1500);
        }
      };
    },
  },
};
</script>

<style scoped lang="less">
:deep(.filter_row) {
  margin-bottom: 0;
  .ant-input-affix-wrapper {
    line-height: 30px;
    padding: 0 10px;
    svg {
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
      margin-right: 5px;
    }
    .ant-input {
      height: 30px;
    }
  }
}
.add_similar_wrapper {
  height: 60vh;
  display: flex;
  flex-direction: column;
  .scroll_wrapper {
    flex-grow: 1;
    overflow: auto;
    .assets_material_list {
      display: flex;
      flex-wrap: wrap;
      .material_item {
        margin: 0 12px 12px 0;
        &:hover {
          transform: translateY(-10px);
          transition: 0.3s;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
        padding: 12px;
        box-sizing: border-box;
        width: 216px;
        height: 188px;
        background: #ffffff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        .material_name {
          // 实现多选框后面的文字在第二行换行
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; /*重点，不能用block等其他*/
          -webkit-line-clamp: 2; /*重点IE和火狐不支持*/
          -webkit-box-orient: vertical; /*重点*/

          text-indent: -28px;
          padding-left: 29px;
          :deep(.ant-checkbox) {
            left: -1px;
          }
        }
        .material_tag {
          margin-left: 0;
          color: rgba(153, 153, 153, 1);
        }
        .source_wrapper {
          height: 108px;
          & > * {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.add_similar_modal {
  .ant-modal-body {
    padding: 0 24px;
  }
}
</style>
