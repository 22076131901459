<!--
 * @Author: Yran
 * @Date: 2022-01-11 15:42:41
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-12 17:27:37
-->
<template>
  <div class="w100 chart_wrapper" :class="{ is_fold: isFold }" :style="style">
    <p class="module_title w100">
      <span class="title_content" v-if="title"> {{ title }} </span>
      <span class="select_group">
        <slot name="filter"></slot>
        <div :class="[isFold ? 'fold' : 'unfold', 'fold-btn', 'pointer']" @click="isFold = !isFold" v-if="showFold">
          收起/展开
          <DoubleRightOutlined />
        </div>
      </span>
    </p>
    <div class="chart w100" ref="chartContainer"></div>
  </div>
</template>

<script>
import { InitChart } from '@/utils/utils.js';
import { DoubleRightOutlined } from '@ant-design/icons-vue';
export default {
  name: 'BaseChart',
  components: { DoubleRightOutlined },
  props: {
    showFold: { type: Boolean, default: false },
    title: { type: String, default: '' },
    chartOption: { type: Object, default: () => {} },
    style: { type: Object, default: () => {} },
  },
  data() {
    return {
      chartInstance: null,
      isFold: false,
      chart_height: this.chartHeight,
    };
  },
  watch: {
    // 监听chart配置变化重绘图形
    chartOption: {
      handler() {
        if (!this.chartInstance?._disposed) {
          this.chartInstance = new InitChart(this.chartOption, this.$refs.chartContainer);
        }
      },
      deep: true,
    },
  },
  methods: {},
};
</script>

<style scoped lang="less">
.chart_wrapper {
  transition: 0.3s;
  height: 216px;
  display: flex;
  flex-direction: column;
  :deep(.module_title) {
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .title_content {
      font-size: 14px;
      font-weight: bold;
      margin-right: 14px;
      .anticon-down {
        margin-left: 14px;
        color: #666666;
      }
      &::before {
        content: '';
        display: inline-block;
        height: 12px;
        width: 4px;
        background-color: #2f70f4;
        margin-right: 10px;
        transform: translateY(1px);
      }
    }
    .select_group {
      @inputHeight: 30px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      box-sizing: border-box;
      & > * {
        margin-right: 16px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-calendar-picker-input {
        height: @inputHeight;
      }
      .ant-select {
        width: 120px;
      }
      .ant-calendar-picker {
        width: 180px;
        .ant-calendar-picker-clear {
          right: 5px !important;
          margin-top: -6px !important;
        }
      }
      .fold-btn {
        height: 30px;
        padding: 0 15px;
        line-height: 28px;
        box-sizing: border-box;
        border: 1px solid rgb(217, 217, 217);
        border-radius: 4px;
        font-size: 12px;
        &:hover {
          color: #5996ff;
          border-color: #5996ff;
        }
        &.unfold svg {
          transform: rotateZ(90deg);
        }
        &.fold svg {
          transform: rotateZ(-90deg);
        }
      }
    }
  }
  .chart {
    flex-grow: 1;
  }
  &.is_fold {
    height: 40px;
    overflow: hidden;
    :deep(.select_group) > *:not(:last-child) {
      display: none;
    }
    .module_title {
      background: #fff;
      z-index: 10;
    }
  }
}
</style>
