<!--
 * @Author: chenxing
 * @Date: 2021-03-23 11:24:05
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-13 13:55:51
-->
<template>
  <div :class="{ 'condition-container': true, unfold: foldTag }">
    <div class="tag-wrapper" style="over-flow: auto" ref="tagWrapper">
      <template v-for="(tag, index) in conditionList" :key="tag.value">
        <div class="condition-tag">
          <span class="tag-title"> {{ tag.label }}：</span>
          <span class="tag-content">{{ tag.value.join('、') }}</span>
          <label class="tag-delete" @click="removeTag(tag, index)"><CloseCircleFilled /></label>
        </div>
      </template>
    </div>
    <svg-icon icon-class="dropDown" @click="foldTag = !foldTag" v-show="showIcon" />
  </div>
</template>

<script>
import { watch, nextTick, reactive, ref, toRef, toRefs } from 'vue';
import { CloseCircleFilled } from '@ant-design/icons-vue';
export default {
  name: 'SelectedConditions',
  emits: ['removeTag'],
  components: { CloseCircleFilled },
  props: {
    // conditionList:[{label:'部门',value:['部门1'，'部门2']},{label:'渠道',value:['巨量引擎']}]
    conditionList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(prop) {
    const { conditionList } = toRefs(prop);
    const conditions = reactive(conditionList);
    let foldTag = ref(false);
    let showIcon = ref(false);
    const tagWrapper = ref(null);
    watch(
      () => prop.conditionList,
      () => {
        setTimeout(() => {
          const height = tagWrapper.value && getComputedStyle(tagWrapper.value).height;
          if (parseFloat(height) > 42) {
            showIcon.value = true;
          } else {
            showIcon.value = false;
          }
        }, 0);
      }
    );
    return { conditions, foldTag, tagWrapper, showIcon };
  },
  methods: {
    removeTag(tag, index) {
      this.$emit('removeTag', { tag, index });
    },
  },
};
</script>

<style lang="less" scoped>
.condition-container {
  position: relative;
  padding-right: 50px;
  max-height: 42px;
  transition: 0.3s;
  overflow: hidden;
  @color-1: #666;
  @color-2: #333;
  @gap: 10px;

  .tag-wrapper {
    overflow: auto;
    .condition-tag {
      font-size: 12px;
      border-radius: 2px;
      background: rgba(238, 242, 246, 1);
      float: left;
      color: @color-2;
      line-height: 32px;
      margin-right: @gap;
      padding: 0 10px;
      display: flex;
      align-items: center;
      margin-top: @gap;

      .tag-title {
        align-self: flex-start;
        flex-grow: 1;
        max-width: 70px;
        flex-shrink: 0;
      }
      .tag-content {
        color: @color-1;
        flex-shrink: 1;
      }
      .tag-delete {
        color: @color-1;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
  :deep(.svg-icon) {
    width: 21px !important;
    height: 22px !important;
    position: absolute;
    right: 13px;
    top: 15px;
    cursor: pointer;
  }
  &.unfold {
    max-height: 1000px !important;
    :deep(.svg-icon) {
      transform: rotateZ(180deg);
    }
  }
}
</style>
