/*
 * @Author: zhangruifeng
 * @Date: 2021-03-04 17:11:04
 * @LastEditors: Yran
 * @LastEditTime: 2021-09-29 18:10:11
 */
import axios, { CancelToken } from '@/utils/axios';

const apiUrl = {
    // 用户列表
    getUser: '/sys/user/list',
    //新增用户
    createUser: '/sys/user/save',
    //更新用户
    updateUser: '/sys/user/update',
    // 部门列表
    getDepartment: '/sys/dept/list',
    // 部门树形数据
    getDepartmentTree: '/sys/dept/getTreeList',
    // 交互部门树形数据
    getDeptAllTreeList: '/sys/dept/getAllTreeList',
    // 创建部门
    createDepartment: '/sys/dept/save',
    // 创建部门
    updateDepartment: '/sys/dept/update',
    //根据部门获取用户
    getDeptUserList: '/sys/user/getByDeptId',
    // 获取角色列表
    getRoleList: '/sys/role/deptId',
    resetPassWord: 'sys/user/reset/password'
};


// 获取用户列表
export function getRoleList(param) {
    return axios.get(`${apiUrl.getRoleList}/${param.deptId}`);
}

// 获取用户列表
export function getUserListData(param) {
    return axios.post(apiUrl.getUser, param);
}

//获取部门
export function getDepartmentData() {
    return axios.get(apiUrl.getDepartment);
}

export function getDepartmentTreeData() {
    return axios.get(apiUrl.getDepartmentTree);
}
export function getDeptAllTreeList() {
    return axios.get(apiUrl.getDeptAllTreeList);
}

export function saveDepartment(params) {
    let url = apiUrl.createDepartment;
    if (params.deptId)
        url = apiUrl.updateDepartment;
    return axios.post(url, params);
}
// 取消部门获取用户请求
export let getDeptUserListDataCancel;
//根据部门获取用户
export function getDeptUserListData(params) {
    return axios.post(apiUrl.getDeptUserList, params, {
        cancelToken: new CancelToken(function executor(c) {
            getDeptUserListDataCancel = c;
        })
    });
}

//更新新增用户
export function saveUserInfo(params) {
    let url = apiUrl.createUser;
    if (params.userId)
        url = apiUrl.updateUser;
    return axios.post(url, params);
}
// 重置密码
export function resetPassWord(params) {
    return axios.post(apiUrl.resetPassWord, params);
}